import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { openPopupWidget } from "react-calendly";
import SlideDown from "react-slidedown";
import AnimatedDiv from "./AnimatedDiv";
import { windowGlobal } from "../utils/windowGlobal";

export const FootBlock = ({ children, title, defaultOpen = false }) => {
	const [isOpen, setIsOpen] = React.useState(defaultOpen)

	const onBlockHeadClick = () => {
		if (window.innerWidth < 768) {
			setIsOpen(!isOpen)
		}
	}
	return (<>
		<h3 className={`footBlockHead ${isOpen ? 'openTab' : '' }`} onClick={onBlockHeadClick}>{title}</h3>
		<div className="footBlock">
			{
				windowGlobal.innerWidth < 768 ? (
					<SlideDown>
						{
							isOpen ? children : null
						}
					</SlideDown>
				) : children
			}
		</div>
	</>
	)
}


const Footer = ({ siteTitle }) => (
<footer className="footer">
	<div className="container">
		<AnimatedDiv className="footManBox">
			<div className="manImg">
				<StaticImage src="../assets/images/olympus-11.png" alt="" />
			</div>
	<div className="footManText">
	<h2>
	<span>Only accepting a</span> limited <span>number of Fraternities</span></h2>
					<div className="desc">
						Olympus will be offered to a limited number of Fraternities! Don't wait another day to get started. Click the button below to schedule a demo today.
					</div>
					<a className="blueBtn" href="#"
						onClick={() => openPopupWidget({
							url: 'https://calendly.com/blueportal/30min',
							pageSettings: {
								backgroundColor: "#000F1F",
								hideEventTypeDetails: true,
								hideLandingPageDetails: true,
								primaryColor: "#000F1F",
								textColor: "#000F1F",
							}
						})}>
						Schedule a Demo
					</a>
				</div>
			</AnimatedDiv>
		</div>

		<div className="footerBg">
			<div className="container">
				<div className="row">
					<div className="col-md-4 footColA">
						<FootBlock title="About us">
							<div className="desc">Olympus is the leading all in one fraternity management software.</div>
							<ul className="footerSocial">
								<li>
									<a href="https://www.facebook.com/olympusorganizationsoftware" className="facebook"></a>
								</li>
								<li>
									<a href="https://www.instagram.com/olympus.software/" className="instagram"></a>
								</li>
								<li>
									<a href="https://www.linkedin.com/company/olympus-organizations-software" className="linkedin"></a>
								</li>
								<li>
									<a href="https://twitter.com/olympussoftwre" className="twitter"></a>
								</li>
							</ul>
						</FootBlock>
					</div>

					<div className="col-md-3 footColB">
						<FootBlock title="Essential Links" defaultOpen={true}>
							<ul className="footLink">
								<li><Link to="/#benefitSec1">Benefits</Link></li>
								<li><Link to="/#pricingSec">Pricing</Link></li>
								<li><Link to="/#testimonials">Testimonials</Link></li>
								<li><Link to="/#contactSec">Contact</Link></li>
							</ul>
						</FootBlock>
					</div>

					<div className="col-md-2 footColC">
						<FootBlock title="Integrations">
							<ul className="footLink">
								<li><a href="https://www.sociocs.com/">Sociocs</a></li>
								<li><a href="https://stripe.com/">Stripe Payments</a></li>
								<li><a href="https://www.mailgun.com/">Mailgun API</a></li>
							</ul>
						</FootBlock>
					</div>
					<div className="col-md-3 footColD">
						<FootBlock title="Contact Info">
							<div className="footContInfo">
								<a href="tel:212-389-2704">212-389-2704</a><br />
							</div>
							<div className="footContInfo email">
								<a href="mailto:connect@olympusweb.io">connect@olympusweb.io</a>
							</div>
							<div className="footContInfo location">
							  80 Broad St New York, New York, 10004 US
							</div>
						</FootBlock>
					</div>
				</div>

				<div className="copyRight">
					<div className="row">
						<div className="col">
							Copyright © {new Date().getFullYear()}. Olympus. All Rights Reserved.
						</div>
						<div className="col-auto">
							<Link to="/">Careers</Link>
							<Link to="/">Privacy Policy</Link>
							<Link to="/">Terms & Conditions</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
)

Footer.propTypes = {
	siteTitle: PropTypes.string
}

Footer.defaultProps = {
	siteTitle: ``
}

export default Footer
