import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { withPrefix, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { openPopupWidget } from "react-calendly";
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { windowGlobal } from '../utils/windowGlobal';

const MagaMenu = () => {
	const closeLinks = () => {
		document.body.classList.remove('openNav')
	}
	return (
		<div className="magaMenu">
			<div className="row">
				<div className="col-md-6">
					<AnchorLink to="/#benefitSec1" className="megaTextBlock scrollAni" onAnchorLinkClick={closeLinks}>
						<StaticImage className="iconImg" src="../assets/images/svg/mega-icon-1.svg" alt="" />
						<div className="textA">Email Marketing</div>
						<div className="textB">Using email marketing is a great way to increase engagement</div>
					</AnchorLink>
				</div>
				<div className="col-md-6">
					<AnchorLink to="/#benefitSec2" className="megaTextBlock scrollAni" onAnchorLinkClick={closeLinks}>
						<StaticImage className="iconImg" src="../assets/images/svg/mega-icon-2.svg" alt="" />
						<div className="textA">Communication Effectively</div>
						<div className="textB">Centralize all communications in one place</div>
					</AnchorLink>
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<AnchorLink to="/#benefitSec3" className="megaTextBlock scrollAni" onAnchorLinkClick={closeLinks}>
						<StaticImage className="iconImg" src="../assets/images/svg/mega-icon-3.svg" alt="" />
						<div className="textA">Manage Events</div>
						<div className="textB">Manage all aspects of an event from RSVP, check-in to payment</div>
					</AnchorLink>
				</div>

				<div className="col-md-6">
					<AnchorLink to="/#benefitSec4" className="megaTextBlock scrollAni" onAnchorLinkClick={closeLinks}>
						<StaticImage className="iconImg" src="../assets/images/svg/mega-icon-4.svg" alt="" />
						<div className="textA">Take Payments</div>
						<div className="textB">Join the thousands of Fraternities and other organizations</div>
					</AnchorLink>
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<AnchorLink to="/#benefitSec5" className="megaTextBlock scrollAni" onAnchorLinkClick={closeLinks}>
						<StaticImage className="iconImg" src="../assets/images/svg/mega-icon-5.svg" alt="" />
						<div className="textA">Manage Users</div>
						<div className="textB">With the right tools, managing a fraternity memberships can be fun and easy</div>
					</AnchorLink>
				</div>
				<div className="col-md-6">
					<AnchorLink to="/#benefitSec6" className="megaTextBlock scrollAni" onAnchorLinkClick={closeLinks}>
						<StaticImage className="iconImg" src="../assets/images/svg/mega-icon-6.svg" alt="" />
						<div className="textA">Online Store</div>
						<div className="textB">Creating an online store for your organization is a great way to raise money</div>
					</AnchorLink>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6">
					<AnchorLink to="/#benefitSec7" className="megaTextBlock scrollAni" onAnchorLinkClick={closeLinks}>
						<StaticImage className="iconImg" src="../assets/images/svg/mega-icon-7.svg" alt="" />
						<div className="textA">Blog</div>
						<div className="textB">Easily create and style blogs, lectures</div>
					</AnchorLink>
				</div>
				<div className="col-md-6">
					<AnchorLink to="/#benefitSec8" className="megaTextBlock scrollAni" onAnchorLinkClick={closeLinks}>
						<StaticImage className="iconImg" src="../assets/images/svg/mega-icon-8.svg" alt="" />
						<div className="textA">Sponsored Business Listing</div>
						<div className="textB">Create a new stream of revenue by offering a space for member to publish their business information</div>
					</AnchorLink>
				</div>
			</div>
		</div>
	)
}

const Header = ({ siteTitle }) => {
	// determined if page has scrolled and if the view is on mobile
	const [scrolled, setScrolled] = useState(false);
	const [activeNav, setActiveNav] = useState(false)
	const [isBenifitMenuOpen, setIsBenifitMenuOpen] = useState(false)

	// change state on scroll
	useEffect(() => {
		const handleScroll = () => {
			const isScrolled = window.scrollY > 50;
			if (isScrolled !== scrolled) {
				setScrolled(!scrolled);
			}
		};

		document.addEventListener('scroll', handleScroll, { passive: true });

		return () => {
			// clean up the event handler when the component unmounts
			document.removeEventListener('scroll', handleScroll);
		};
	}, [scrolled]);

	useEffect(() => {
		if (activeNav) {
			document.body.classList.add('openNav')
		} else {
			document.body.classList.remove('openNav')
		}
	}, [activeNav])


	return (
		<header className={scrolled ? 'header headerFix' : 'header'}>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-auto colA">
						<div className="logo">
							<a href="/" className="wh"><img src={withPrefix("images/logo.png")} alt="" /></a>
							<a href="/" className="bl"><img src={withPrefix("/images/logo-black.png")} alt="" /></a>
						</div>
					</div>
					<div className="col colB">
						<div className="mainNav">
							<button className="mobileNavBtn" onClick={() => setActiveNav(!activeNav)}>
								<span></span>
								<span></span>
								<span></span>
							</button>

							<div className="overLay" onClick={() => setActiveNav(!activeNav)}></div>
							<nav className="navigation">
								<ul>
									<li className={`hasChild ${isBenifitMenuOpen ? 'openSubMenu' : ''}`} onClick={() => window.innerWidth < 1026 && setIsBenifitMenuOpen(!isBenifitMenuOpen)}>
										<a className="benefit-link" href="#">Benefits</a>
										{
											windowGlobal?.innerWidth < 1026 ? (
												<SlideDown>
													{
														isBenifitMenuOpen ? (
															<MagaMenu />
														) : null
													}
												</SlideDown>
											) : <MagaMenu />
										}
									</li>
									<li><AnchorLink className="scrollAni" to="/#pricingSec">Pricing</AnchorLink></li>
									<li><AnchorLink className="scrollAni" to="/#faqSec">FAQ</AnchorLink></li>
									<li><AnchorLink className="scrollAni" to="/#contactSec">Contact</AnchorLink></li>
								</ul>
								<a
									className="yellowBtn mobileBtn" href="#"
									onClick={() => openPopupWidget({
										url: 'https://calendly.com/blueportal/30min',
										pageSettings: {
											backgroundColor: "#000F1F",
											hideEventTypeDetails: true,
											hideLandingPageDetails: true,
											primaryColor: "#000F1F",
											textColor: "#000F1F",
										}
									})}>
									Schedule a Demo
								</a>
							</nav>
						</div>
					</div>

					<div className="col-auto colC">
						<div className="topRight">
							<a className="textLink" href="https://admin.olympusweb.io/">Login</a>
							<a className="yellowBtn" href="#"
								onClick={() => openPopupWidget({
									url: 'https://calendly.com/blueportal/30min',
									pageSettings: {
										backgroundColor: "#000F1F",
										hideEventTypeDetails: true,
										hideLandingPageDetails: true,
										primaryColor: "#000F1F",
										textColor: "#000F1F",
									}
								})}>
								Schedule a Demo
							</a>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header
