import React, { useEffect, useState } from "react"
import { useInView } from "react-hook-inview"

const AnimatedDiv = props => {
  const [animationClass, setAnimationClass] = useState("")

  const [ref, inView] = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView) {
      setAnimationClass("ani")
    }
  }, [inView])

  return (
    <div {...props} ref={ref} className={`${props.className} animation ${animationClass}`}>
      {props.children}
    </div>
  )
}

export default AnimatedDiv